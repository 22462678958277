.App {
  /* margin-top: 86px; */
  background-color: #f9f9f9;
  /* font-size: 20px; */
}

p {
  font-size: 20px;
}

.klt_spacer {
  height: 25px;
  width: 100%;
}
/* BLOG */
.bts_blog {
    padding: 50px 80px;
    display: flex;
    gap: 50px;
    position: relative;
    flex-wrap: wrap;
}

.bts_blog > div:first-child {
    width: 70%;
}

/* SIDEBAR */

.bts_blog > div:last-child {
    width: 25%;
}

.bts_sidebar {
    position: sticky;
    top: 150px;
}

/* TRENDING CONTAINER */

.bts_trending {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.bts_trending > h2 {
    font-size: 24px;
    font-weight: 600;
    border-bottom: 2px solid var(--primaryColor);
    padding-bottom: 10px;
    margin: 10px 0;
}

/* SIDEBAR-TRENDING CARD */

.bts_trending-card {
    border: 1px solid rgba(211, 211, 211, 0.713);
    border-radius: 20px;
    background-color: #fff;
    width: 85%;
    padding: 25px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
    transition: .5s;
}

.bts_trending-card:hover {
    box-shadow: 0 0 10px rgba(211, 211, 211, 0.754);
}

.bts_trending-card > h3, .bts_trending-card > p {
    margin: 0;
}

.bts_trending-card > h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}

/*  SIDEBAR-RECENTS CARD */

.bts_recent-card_cont {
    display: flex;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
}

/* .bts_recent-card_cont:hover > .bts_recent-card > h4 {
    text-decoration: underline;
} */

.bts_recent-card {
    display: flex;
    gap: 20px;
    align-items: center;
}

.bts_recent-card_img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 15px;
}

.bts_recent-card > h4 {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    margin: 0;
}

/* BLOG- MAIN */

.bts_blogs_main {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
}

/* BLOG CARD */

.bts_blog-card {
    border-radius: 20px;
    position: relative;
    background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 10px 0 #58585833;
    backdrop-filter: blur( 7px );
    -webkit-backdrop-filter: blur( 7px );
    /* border-radius: 10px; */
    border: 1px solid #fa921c69;
    transition: .3s ease;
    cursor: pointer;
    min-width: 350px;
    /* max-width: 360px; */
    overflow: hidden;
    width: 45%;
    color: black;
}

.bts_blog-card:hover {
    color: black;
}

.bts_blog-card_img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
}


.bts_blog_img1 {
    background-image: url('./assets/blog_img_sample.jpg');
}

.bts_blog_img_1 {
    background-image: url('./assets/The-Ultimate-Guide-to-Choosing-the-Best-Towing-Service-for-Your-Vehicle.png');
}

.bts_blog_img_2 {
    background-image: url('./assets/Top-5-Towing-Mistakes-to-Avoid-What-You-Need-to-Know.png');
}

.bts_blog_img_3 {
    background-image: url('./assets/fix-tyre.jpg');
}

.bts_blog_img_4 {
    background-image: url('./assets/vehicle breakdown.jpg');
}

.bts_blog_img_5 {
    background-image: url('./assets/towing-costs.jpg');
}

.bts_blog_img_6 {
    background-image: url('./assets/tow-truck-tools.jpg');
}

.bts_blog_img2 {
    background-image: url('./assets/services.jpg');
}

.bts_blog-card_detail {
    padding: 5px 15px;
}

.bts_blog-card_detail > Link > p {
    transition: .5s;
}

.bts_blog-card_detail > Link > p:hover {
    border-bottom: 1px solid var(--primaryColor);
    padding: 4px 5px;
    width: max-content;
}

.bts_blog-card_detail > h3 {
    font-size: 24px;
    line-height: 30px;
    margin: 15px 0;
    width: 80%;
}

.bts_blog-card_detail > div {
    display: flex;
    justify-content: space-between;
}

/* BLOG POST */

.bts_blog-post {
    padding: 50px 80px;
    display: flex;
    gap: 30px;
}

.bts_blog-post > div:first-child {
    box-sizing: border-box;
    /* padding-right: 30px; */
    width: 100%;
}

.bts_blog-post_head > h1 {
    font-size: 40px;
    margin: 0;
    margin-bottom: 20px;
    line-height: 50px;
}

.bts_blog-post_img {
    width: 100%;
}

.bts_blog-post_cat {
    width: max-content;
}

.bts_blog-post_author {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.bts_blog-post_author_cont {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
}

@media screen and (max-width: 1200px) {
    .bts_blog {
        flex-direction: column;
    }

    .bts_blog > div:first-child {
        width: 100%;
    }

    .bts_blog > div:last-child {
        width: 100%;
    }

    .bts_blog-post {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1024px) {
    .bts_blog, .bts_blog-post {
        padding: 30px 50px;
    }
}

@media screen and (max-width: 910px) {
    .bts_blog-card {
        min-width: none;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .bts_blog, .bts_blog-post {
        padding: 30px 40px;
    }
}

@media screen and (max-width: 480px) {
    .bts_blog-card {
        min-width: 100%;
    }

    .bts_blog, .bts_blog-post {
        padding: 30px 30px;
    }
}

@media screen and (max-width: 420px) {
    .bts_blog, .bts_blog-post {
        padding: 30px 20px;
    }
}

.bts_blog-post_content > a {
    color: #fd2929 !important;
}
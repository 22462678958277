.klt_admin {
    height: 100vh;
    padding-top: 120px;
    /* padding-top: 100px; */
    /* box-sizing: border-box; */
}

.invoice-form {
    height: 100vh;
    padding-top: 120px;
   
}

.invoice-form-cont {
   
    max-width: 500px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 12px #00000033;
    border-radius: 15px;
    padding: 20px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: inherit;
    box-sizing: border-box;
}

.invoice-form-cont > input {
    border-radius: 10px;
    padding: 10px;
    width: 92%;
    margin-right: 20px;
}

.invoice-form-cont > button {
    background-color: black;
    color: white;
    font-family: 'Outfit', sans-serif;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
}

@media screen and (max-width: 500px) {
    .invoice-form-cont {
        width: 100%;
    }
}
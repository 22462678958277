.klt_about_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.klt_aboutUs_row > div {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.klt_aboutUs_row > div > h2 {
    margin: 10px;
}

.klt_aboutUs_row > div > h3 {
    font-size: 48px;
    font-weight: 600;
    margin: 10px;
}

.klt_aboutUs_row2 > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 50px;
}

.klt_aboutUs_row2 > div > div {
    width: 350px;
    /* border: 1px solid ; */
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 8px lightgrey;
    border-radius: 15px;

}

.klt_aboutUs_row2 > div > div > h3 {
    font-size: 26px;
}

.padd {
    padding: 40px 150px;
}

@media screen and (max-width: 768px) {
    .klt_contact_col > h3 {
        font-size: 40px !important;
        text-align: center;
    }
    .kl_hero_text {
        font-weight: 600 !important;
        line-height: 40px !important;
    }
    .klt_aboutUs_row2 > div {
        flex-wrap: wrap;
        padding: 0 25px;
    }
}
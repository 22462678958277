.klt_info_bar {
    /* box-sizing: border-box; */
    /* padding: 20px 0; */
    width: 100%;
    height: 200px;
    background-color: black;
    color: white;
    align-items: center;
    justify-content: center;
    margin-top: -3rem;
    z-index: -1;
    /* text-align: center; */
}

.klt_info_bar > a {
    box-sizing: border-box;
    background-image: url('../hero/asset/tyre_red.png');
    background-position: 30px;
    background-size: 25rem;
    width: 100%;
    height: 100%;
    color: white;
    text-decoration: none;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 150px;
    padding-left: 400px;
}

.klt_info_bar_cta {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: white;
    color: black;
    padding: 15px 25px;
}

@media screen and (max-width: 768px) {
    .klt_info_bar {
        height: max-content;
    }

    .klt_info_bar > a {
        padding: 0;
        flex-direction: column;
        align-items: center;
        background-image: none;
    }

    .klt_info_bar > a > div > p {
        text-align: center;
    }
}
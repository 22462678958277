.kl-gallery_row:first-child {
    margin-top: 50px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kl-gallery_row:first-child > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
}

.kl-gallery_row:first-child > div > h3 {
    text-align: center;
    font-size: 58px;
    margin: 5px 0;
    font-weight: 500;
}
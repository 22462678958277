.bts_drawer_container {
    box-sizing: border-box;
    position: absolute;
    background-color: #fd2929;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    overflow: hidden;
}

.bts_slide_in {
    /* display: none; */
    width: 0%;
}

.slide_in {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: slide-in .7s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    /* align-items: center; */
    /* justify-content: center; */
    padding-left: 0px;
}

.bts_slide_out {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* justify-content: center; */
    /* padding-left: 80px; */
    animation: slide-out .7s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@keyframes slide-out {
    from {
        width: 0px;
    }
    to {
        width: 100%;
    }
}

@keyframes slide-in {
    from {
        width: 100%;
        /* padding-left: 80px; */
    }
    to {
        width: 0px;
        /* padding-left: 0px; */
    }
}

.bts_drawer_logo {
    width: 80px;
    margin-top: 10px;
    margin-left: 20px;
}

.bts_drawer_footer {
    border-top: 1px solid var(--primaryColor);
    margin: 0 25px;
    box-sizing: border-box;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

/* ADDED COPY */

.bts_navitems {
    box-sizing: border-box;
    width: 100% !important;
    display: flex;
    /* gap: 15px; */
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-between;
    margin: 13px 0;
}

.bts_navitems > li {
    font-size: 16px;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.bts_navitems > li:hover {
    border-bottom: 2px solid var(--primaryColor);
    padding-bottom: 5px;
}

.bts_nav_link {
    color: black !important;
    font-weight: 400;
}

.bts_navitem {
    position: relative;
}

/* MOBILE MENU */

.bts_mobile_navitems {
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-width: 300px;
    margin-top: 8rem;
    margin-left: 30px;
    list-style: none;
}

.bts_mobile_navitem {
    position: relative;
    font-size: 24px;
    color: white;
}

.bts_mobile_subnavitem_cont {
    margin-top: 20px;
    margin-left: 20px;
}

.bts_mobile_subnavitem {
    color: white;
    padding: 5px 0;
}

.bts-arrow-cont {
    z-index: 10;
    background-color: var(--primaryColor);
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 5px;
    left: 130px;
}

/* FOOTER MENU */
.bts_footer_navitems {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.bts_footer_navitems > a {
    color: white;
}

.bts_footer_navitems > a:hover {
    color: var(--primaryColor);
}
.klt_services {
    padding: 40px 150px;
    margin-top: 30px;
}

.klt_services_row:first-child > div {
    text-align: center;
}

.klt_services_row:first-child > div > h3 {
    font-size: 48px;
    margin: 0 10px;
}

.klt_services_row:first-child > div > p {
    font-size: 18px;
    line-height: 29px;
}

.klt_services_row:nth-child(2) {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 80px;
}

.klt_services_row:nth-child(2) > div {
    width: 40%;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
}

.klt_services_row:last-child {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.klt_services_row:last-child > div {
    box-sizing: border-box;
    width: 29%;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
}

.red_box {
    background-color: #fd2929 !important;
    color: white;
}

.klt_services_cta {
   background-color: black;
   padding: 10px 20px;
   color: white;
   font-family: inherit;
   display: flex;
   gap: 20px;
   border: none;
}

.link {
    color: white !important;
}

@media screen and (max-width: 768px) {
    .klt_services {
        padding: 40px 35px;
    }

    .klt_services_row:last-child {
        flex-direction: column;
    }

    .klt_services_row:last-child > div {
        width: 100%;
    }

    .klt_services_row:nth-child(2) {
        flex-direction: column;
    }

    .klt_services_row:nth-child(2) > div {
        box-sizing: border-box;
        width: 100%;
    }
}
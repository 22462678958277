.klt_faq {
    padding: 40px 150px;
    margin: 30px 0;
}

.klt_faq_row {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.klt_faq_col {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.klt_faq_col > div {
    box-sizing: border-box;
    background-color: white;
    border-radius: 15px;
    width: 550px;
    height: 150px;
    padding: 25px 25px;
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: flex-start;
    justify-content: flex-start;
}

.klt_faq_col > div > div:first-child {
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fd2929;
    display: flex;
    align-items: center;
    justify-content: center;
}

.klt_question_mark {

}

.klt_faq_col > div > div:last-child > h3 {
    margin: 0;
}

.klt_faq_col > div > div:last-child > p {
    font-size: 16px
}

.klt_faq_col:last-child {
    margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
    .klt_faq {
        padding: 0 35px;
    }

    .klt_faq_row {
        flex-direction: column;
    }

    .klt_faq_col > div {
        margin: auto;
        width: 100%;
        height: max-content;
    }

    .klt_faq_col > p {
        text-align: center;
    }
}
.klt_testimonial {
    padding: 40px 150px;
    margin-top: 30px;
}
.klt_testimonial_row:first-child > div {
    text-align: center;
}

.klt_testimonial_row:first-child > div > h3 {
    font-size: 48px;
    margin: 0 10px;
}

.klt_testimonial_row:first-child > div > p {
    font-size: 18px;
    line-height: 29px;
}

.klt_testimonial_row:nth-child(2) {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 80px;
}

.klt_testimonial_row:nth-child(2) > div {
    width: 40%;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.klt_testimonial_row:nth-child(2) > div > p {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .klt_testimonial {
        padding: 0 35px;
    }

    .klt_testimonial_row {
        flex-direction: column;
    }

    .klt_testimonial_row:nth-child(2) > div {
        width: 100%;
        box-sizing: border-box;
    }
}
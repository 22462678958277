.klt_contact_row:first-child {
    padding-top: 100px;
}

.klt_contact_row:first-child > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    justify-content: center;
}

.klt_contact_row:first-child > div > h3 {
    font-size: 58px;
    margin: 5px 0; 
    font-weight: 500;
}

.klt_contact_text {
    color: #fd2929;
}

.klt_contact_row:nth-child(2) {
    width: 75%;
    height: 700px;
    display: flex;
    margin: auto;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0 0 6px lightgray;
    overflow: hidden;
}

.klt_contact_row:nth-child(2) > div {
    width: 50%;
}

.klt_contact_row:nth-child(2) > div:first-child {
    box-sizing: border-box;
    padding: 50px;
}

.klt_contact_row:nth-child(2) > div:first-child > p {
    font-weight: 600;
    font-size: 35px;
}

.klt_contact_row:nth-child(2) > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

legend {
    margin: 30px 0;
}

label {
    font-family: inherit;
    font-size: 18px;
}

input {
    width: 100%;
    height: 40px;
    border: 1px solid lightgrey;
}

textarea {
    width: 100%;
    height: 120px;
    border: 1px solid lightgrey;
}

.klt_form_submit {
    width: 100%;
    height: 50px;
    font-family: inherit;
    font-size: 17px;
    border: none;
    background-color: #fd2929;
    color: white;
    border-radius: 10px;
}
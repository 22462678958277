.klt_about {
    padding: 40px 150px;
    margin-top: 30px;
}

.klt_about_row {
    display: flex;
    gap: 20px;
}

.klt_about_col {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.klt_about_col > img {
    width: 90%;
}

.klt_about_col > h2 {
    margin: 0;
}

.klt_about_col > h3 {
    font-size: 48px;
    margin: 15px 0;
}

.klt_about_col > div {
    display: flex;
    /* align-items: center; */
    gap: 10px;
}

.klt_about_col > div > div:first-child {
    min-width: 40px;
    height: 40px;
    background-color: #fd2929;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
}
 
.klt_about_col > div > div:last-child > h3 {
    font-size: 23px;
    margin: 0px 0;
}

.major {
    width: 100%;
    background-color: black !important;
}

@media screen and (max-width: 768px) {
    .klt_about {
        padding: 0 35px;
    }
    .klt_about_row {
        flex-direction: column;
    }
    .klt_about_row:last-child {
        flex-direction: column-reverse;
    }
    .klt_about_col {
        width: 100%;
    }
    .klt_about_col > img {
        margin: auto;
    }
}
.klt_hero {
    height: 100vh;
    display: flex;
    background-image: url('./asset/klt_hero_img.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    padding: 0 80px;
}

.klt_hero_row, .klt_hero_col {
    height: 100%;
    display: flex;
}

.klt_hero_row {
    width: 100%;
}

.klt_hero_col {
    flex-direction: column;
    justify-content: center;
}

.hero_details {
    width: 50%;
}

.bg_img {
    
}

.video_section {
    width: 50%;
    align-items: center;
    position: relative;
}

.video_section > a {
    padding: 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fd2929;
    display: flex;
    align-items: center;
    justify-content: center;
}

.klt_colored_text {
    color: #fd2929;
}

.klt_hero_col > h1 {
    font-size: 21px;
}

.klt_hero_col > h2 {
    font-size: 68px;
    margin: 0;
}

.klt_cta_btns {
    display: flex;
    gap: 30px;
    margin-top: 40px;
}

.klt_cta_btns > a > .red {
    background-color: #fd2929 !important;
    color: white;
    cursor: pointer;
}

.klt_cta_btn:last-child {
    background-color: black;
    color: white;
}
 
.klt_cta_btn {
    font-family: inherit;
    padding: 20px 30px;
    margin: 10px 0;
    border-radius: 10px;
    border: none;
}

.play_icon {
    margin: 0;
    padding: 0;
}

.klt_info_box {
    box-sizing: border-box;
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 400px;
    height: 180px;
    background-color: black;
    /* box-shadow: 0 0 8px lightgrey; */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.klt_info_box > a {
    box-sizing: border-box;
    padding: 20px 30px;
    width: 100%;
    height: 100%;
    background-image: url('./asset/tyre_red.png');
    background-repeat: no-repeat;
}

.klt_info_box > a > p {
    color: white
}

.klt_info_box > a > p:last-child {
    text-align: right;
    text-decoration: underline;
}

.klt_info_box > a > p:last-child > a {
    color: white !important;
}

.klt_info_box_phone {
    font-size: 42px;
    font-weight: 700;
    margin: 0;
    transition: .5s;
}

.klt_info_box_phone:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .klt_hero {
        height: max-content;
        padding: 120px 25px;
        background: #f9f9f9;
    }

    .hero_details > h2 {
        font-size: 50px;
    }

    .klt_hero_col {
        width: 100%;
    }
}
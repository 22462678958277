@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 768px) {
  .padd {
    padding: 40px 25px !important;
  }

  .klt_contact_row {
    box-sizing: border-box;
    width: 100% !important;
    padding: 0 20px;
    flex-direction: column-reverse !important;
    box-shadow: none !important;
    background-color: transparent !important;;
  }

  .klt_contact_row > div {
    width: 100% !important;
  }

  .klt_contact_row:nth-child(2) > div:first-child {
    padding: 0px !important;
  }
  
  .klt_contact_row:nth-child(2) {
    height: fit-content !important;
    overflow: hidden !important;
  }
  
  .klt_contact_row:nth-child(2) > img {
    height: 400px !important;
  }
  
  .klt_info_bar {
    margin-top: 0px !important;
  }
  
  .contact_sup {
    height: max-content !important;
  }

  .contact_sup_form {
    height: max-content !important;
    margin-bottom: 25px;
  }

}
.klt_footer {
    background-color: #d4d4d4;
}

.klt_footer > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.klt_footer > span > a {
    font-size: 19px;
}

@media screen and (max-width: 768px) {
    .klt_footer > span > p {
        font-size: 16px;
    }

    .klt_footer > span > a {
        font-size: 16px;
    }
}